import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import PlayAround from "./pages/PlayAround";
import PlayAround2 from "./pages/PlayAround2";
import "./fonts/inter/Inter-VariableFont.ttf";
import "./App.scss";
import "./pages/Layout.scss";
import Loader from "./components/Loader";
import { Suspense } from "react";
import Login from "./components/login-signup/Login";
import Signup from "./components/login-signup/Signup";
import HeaderContextProvider from "./store/Header-context";
import EmailVerification from "./components/login-signup/EmailVerification";
import AuthGuard from "./components/AuthGuard";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Homepage from "./components/Homepage";
import ResetPassword from "./components/ResetPassword";
import CreatePassword from "./components/login-signup/CreatePassword";
import PasswordResetSuccess from "./components/login-signup/PasswordResetSuccess";
import PlayAround3 from "./pages/PlayAround3";
import SelectEnv from "./components/addProducts/SelectEnv";
import EnvStore from "./components/addProducts/EnvStore";
import AddProducts from "./components/addProducts/AddProducts";
import ThreeScreen from "./components/addProducts/ThreeScene"
import Game from "./pages/Game";
import CompanyProfile from "./components/CompanyProfile";
import PaymentSubmission from "./components/PaymentSubmission";
import Subscription from "./components/Subscription";
import SpinTheWheel from "./pages/SpinTheWheel";
import QuizGame from "./pages/QuizGame";
import Launch from "./components/Launch";
import Congratulations from "./components/Congratulations";
import Env from "./components/client/Env";
import CouponCodeQuiz from "./pages/CouponCodeQuiz";
import AccountCreated from "./components/AccountCreated";
import PasswordVerification from "./components/login-signup/PasswordVerification";
import Refresh from "./components/addProducts/Refresh";
import ComingSoon from "./components/ComingSoon";
import Testing from "./components/addProducts/Testing";
import CustomProduct from "./pages/CustomProduct";
import CustomEnv from "./pages/CustomEnv";
import ProductAnalytic from "./pages/ProductAnalytic";

function App() {
 

  return (
    <HeaderContextProvider>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="play-around" element={<PlayAround/>} />
              <Route path="create-password" element={<CreatePassword/>} />
              <Route path="play-around2" element={<PlayAround2/>} />
              <Route path='verify-email/*' element={<ResetPassword/>} />
              <Route path='set-password/*' element={<ResetPassword/>} />
              <Route path='account-created' element={<AccountCreated/>} />
              <Route path='set-password/*' element={<ResetPassword/>} />
              <Route path='account-created' element={<AccountCreated/>} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="Email-verification" element={<EmailVerification />} />   
              <Route path="password-verification" element={<PasswordVerification />} />    
              <Route path="resetsuccess" element={<PasswordResetSuccess/>} /> 
              <Route path="testing" element={<Testing/>} /> 
                      
             
               
              
                      
              <Route path="dashboard" element={<AuthGuard><Dashboard /></AuthGuard>} >
                <Route path="" element={<AuthGuard><Homepage /></AuthGuard>} />
                <Route path="company-profile" element={<AuthGuard><CompanyProfile/></AuthGuard>}></Route>
                <Route path="add-game" element={<AuthGuard><Game/></AuthGuard>}></Route>
                <Route path="select-store" element={<AuthGuard><EnvStore/></AuthGuard>}></Route>
                <Route path="add-products" element={<AuthGuard><AddProducts/></AuthGuard>}></Route>         
                <Route path="coming-soon" element={<AuthGuard><ComingSoon/></AuthGuard>} />
                <Route path="product-analytics" element={<AuthGuard><ProductAnalytic/></AuthGuard>}></Route>        
                    
                <Route path="subscription" element={<AuthGuard><Subscription/></AuthGuard>} /> 
                <Route path="spin" element={<AuthGuard><SpinTheWheel/></AuthGuard>} />                              
                <Route path="quiz" element={<AuthGuard><QuizGame/></AuthGuard>} />  
                <Route path="add-coupon-code" element={<AuthGuard><CouponCodeQuiz/></AuthGuard>} />                              
              </Route>
              <Route path="resetpassword" element={<AuthGuard><ResetPassword /></AuthGuard>} />             
              <Route path="payment-form" element={<AuthGuard><PaymentSubmission/></AuthGuard>}/>
              {/* <Route path="game/play-around3" element={<AuthGuard><PlayAround3/></AuthGuard>} /> */}
              <Route path="game/play-around" element={<AuthGuard><PlayAround/></AuthGuard>} />


              <Route path="select-enviroment" element={<AuthGuard><SelectEnv/></AuthGuard>}></Route>              
              <Route path="playarea/*" element={<AuthGuard><ThreeScreen/></AuthGuard>}></Route>             
              {/* <Route path="playarea/*" element={<AuthGuard><CustomEnv/></AuthGuard>}></Route> */}
             {/* <Route path="customize-env/*" element={<AuthGuard><ThreeScreen/></AuthGuard>}></Route> */}
             <Route path="customize-env/*" element={<AuthGuard><CustomProduct/></AuthGuard>}></Route>
             <Route path="launch" element={<AuthGuard><Launch/></AuthGuard>}></Route>
             <Route path="congratulation/env" element={<AuthGuard><Congratulations/></AuthGuard>}></Route>            
             <Route path="/:companyName/playAround/*" element={<Env/>}/>

             <Route path="*" element={<Home />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </HeaderContextProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import VideoPopup from "../VideoPopup";
import SideNav from "../SideNav";
import Header from "../Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SelectEnv.scss";
import "./EnvStore.scss";
import { ReactComponent as PlayIcon } from "../../assests/svg/playIcon.svg";
import SetUpGuide from "../SetUpGuide";
import { useNavigate } from "react-router-dom";
import PopupPage from "../PopupPage";

function SelectEnv() {
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.token);
  const [envList, setEnvList] = useState({});
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [videoData, setVideoData] = useState("");
  const [selectedEnvInfo, setSelectedEnvInfo] = useState({});
  const [currentStep,setCurrentStep] = useState(0);
  const [openPopup, setPopupOpen] = useState(false);
const[openPopupSetupGuide,setOpenPopupSetupGuide]=useState(false);

const navigate=useNavigate();

  useEffect(() => {
    async function getEnv() {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.environmentTemplate}?page=1&limit=10`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.status === 200) {
        setCurrentStep(info.data.data.completed_step);
      }
      if (response.status === 200) {
        setEnvList(response.data.data);
      }
      if (response.status === 401) {
        navigate('/login');
      }
      setLoading(false);
    }
    getEnv();

  }, []);

  const handlePopup = (data) => {
    setVideoPopup(data);
  };

  const playPopup = (data) => {
    setVideoData(data.video_url);
    setSelectedEnvInfo({
      title: data.template_name,
      template_features:data.template_features,
      description: data.template_description,
      id: data.env_template_id,
    });
    setVideoPopup(!playVideoPopup);
  };

  const handlePopup2 = (data) => {
    setPopupOpen(false);
  };
 
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="dashboard-header">
        <Header variation={3}></Header>
      </div>

      <div className="dashboard-layout">
        <SideNav />

        <div className="home-section">
          <div className="main-content">
            <div className="env-store-add-product-heading">
              <h2>Select and Customise Your Environment</h2>
              <div className="stepper-container">
                <div className="stepper">
                  <div className="round active">1</div>
                  <div className="round step-not-active" onClick={() => navigate("/dashboard/select-store")}>2</div>
                  <div className="round step-not-active" onClick={() => {
                    if(currentStep < 3){
                      setPopupOpen(true);
                    }
                    else{
                      navigate("/customize-env")
                    }
                    }}>3</div>
                  <div className="round step-not-active" onClick={() => navigate("/dashboard/add-game")} >4</div>
                  <div className="round step-not-active">5</div>
                </div>
                <div className="setupguide-btn">
                  <button onClick={()=>setOpenPopupSetupGuide(true)}>View Setup Guide</button>
                </div>
              </div>
            </div>

            <div className="env-slider-container">
              <Slider {...settings}>
                {envList.length > 0 ? (
                  envList.map((item, index) => (
                    <div className="item" key={index} onClick={() => playPopup(item)}>
                      <img src={item.video_thumbnail_url} alt="img" style={{cursor:'pointer'}}/>
                      <span className="play-icon" >
                        <PlayIcon cursor={'pointer'}/>
                      </span>
                      <span className="title"> {item.template_name}</span>
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {playVideoPopup && (
        <VideoPopup
        open={playVideoPopup}
          videoUrl={videoData}
          closePopup={handlePopup}
          data={selectedEnvInfo}
        />
      )}
      {openPopupSetupGuide && (<SetUpGuide openPopupSetupGuide={openPopupSetupGuide} setOpenPopupSetupGuide={setOpenPopupSetupGuide}/>)}
      {openPopup && <PopupPage  closePopup={handlePopup2}/>}
    </>
  );
}

export default SelectEnv;
